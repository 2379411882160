<template>
  <div class="main-content">
    <div v-if="isLoading" class="loading_page spinner spinner-primary mr-3"></div>
    <b-card class="wrapper" v-if="!isLoading">
      <vue-good-table
        mode="remote"
        :columns="columns"
        :totalRows="totalRows"
        :rows="designations"
        @on-page-change="onPageChange"
        @on-per-page-change="onPerPageChange"
        @on-sort-change="onSortChange"
        @on-search="onSearch"
        :search-options="{
        enabled: false,
        placeholder: $t('Search this table'),  
      }"
        :select-options="{ 
          enabled: false ,
          clearSelectionText: '',
        }"
        @on-selected-rows-change="selectionChanged"
        :pagination-options="{
        enabled: true,
        mode: 'records',
        nextLabel: 'next',
        prevLabel: 'prev',
      }"
        styleClass="vgt-table bordered striped table-hover"
      >
        <div slot="selected-row-actions">
          <button class="btn btn-danger btn-sm" @click="delete_by_selected()">{{$t('Del')}}</button>
        </div>
        <div slot="table-actions">
          <b-button
            @click="New_Designation()"
            class="btn-rounded"
            variant="btn btn-primary btn-icon mr-1"
          >
            {{$t('Add')}}
          </b-button>
        </div>

        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'">
            <a @click="Edit_Designation(props.row)" class="cursor-pointer" title="Edit" v-b-tooltip.hover>
              <img :src="$helpers.getIcons('edit')" alt="" class="cursor-pointer action-icon-grid">
            </a>
            <a title="Delete" v-b-tooltip.hover class="cursor-pointer" @click="Remove_Designation(props.row.id)">
              <img :src="$helpers.getIcons('delete')" alt="" class="cursor-pointer action-icon-grid">
            </a>
          </span>
        </template>
      </vue-good-table>
    </b-card>

    <validation-observer ref="Create_Designation">
      <b-modal hide-footer size="md" id="New_Designation" :title="editmode?$t('Edit'):$t('Add')">
        <b-form @submit.prevent="Submit_Designation">
          <b-row>
                <!-- Department -->
                <b-col md="12">
                  <validation-provider name="Department" :rules="{ required: true}">
                    <b-form-group slot-scope="{ valid, errors }" :label="$t('Department') + ' ' + '*'">
                      <v-select
                        :class="{'is-invalid': !!errors.length}"
                        :state="errors[0] ? false : (valid ? true : null)"
                        v-model="designation.department_id"
                        class="required"
                        required
                        @input="Selected_Department"
                        :placeholder="$t('Department')"
                        :reduce="label => label.value"
                        :options="departments.map(departments => ({label: departments.department, value: departments.id}))"
                      />
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

              <!-- Designation -->
              <b-col md="12">
                <validation-provider
                  name="Designation"
                  :rules="{ required: true}"
                  v-slot="validationContext"
                >
                  <b-form-group :label="$t('Designation') + ' ' + '*'">
                    <b-form-input
                      :placeholder="$t('Designation')"
                      :state="getValidationState(validationContext)"
                      aria-describedby="Designation-feedback"
                      label="Designation"
                      v-model="designation.designation"
                    ></b-form-input>
                    <b-form-invalid-feedback id="designation-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

            <b-col md="12" class="mt-3">
                <b-button variant="primary" type="submit"  :disabled="SubmitProcessing"><i class="i-Yes me-2 font-weight-bold"></i> {{$t('submit')}}</b-button>
                  <div v-once class="typo__p" v-if="SubmitProcessing">
                    <div class="spinner sm spinner-primary mt-3"></div>
                  </div>
            </b-col>

          </b-row>
        </b-form>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import Vue from 'vue'
import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
const Fire = new Vue();
const apiUrl = `${jwtDefaultConfig.apiEndpoint}/hrm`;
import { hideLoader, showErrorNotification, showLoader, showSuccessNotification} from '@/@core/comp-functions/ui/app'

export default {
  metaInfo: {
    title: "Designation"
  },
  components: {
    VueGoodTable,
  },
  data() {
    return {
      isLoading: true,
      SubmitProcessing:false,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "id",
          type: "desc"
        },
        page: 1,
        perPage: 10
      },
      selectedIds: [],
      totalRows: "",
      search: "",
      limit: "10",
      editmode: false,
      designations: [], 
      departments: [],
      companies :[],
      designation: {
          designation: "",
          department_id: "",
      }, 
    };
  },

  computed: {
    columns() {
      return [
        {
          label: this.$t("Designation"),
          field: "designation",
          tdClass: "text-center",
          thClass: "text-center"
        },
        {
          label: this.$t("Department"),
          field: "department_name",
          tdClass: "text-center",
          thClass: "text-center"
        },
        {
          label: this.$t("Action"),
          field: "actions",
          html: true,
          tdClass: "text-center",
          thClass: "text-center",
          sortable: false
        }
      ];
    }
  },

  methods: {
    //---- update Params Table
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    //---- Event Page Change
    onPageChange({ currentPage }) {
      if (this.serverParams.page !== currentPage) {
        this.updateParams({ page: currentPage });
        this.Get_Designation(currentPage);
      }
    },

    //---- Event Per Page Change
    onPerPageChange({ currentPerPage }) {
      if (this.limit !== currentPerPage) {
        this.limit = currentPerPage;
        this.updateParams({ page: 1, perPage: currentPerPage });
        this.Get_Designation(1);
      }
    },

    //---- Event Select Rows
    selectionChanged({ selectedRows }) {
      this.selectedIds = [];
      selectedRows.forEach((row, index) => {
        this.selectedIds.push(row.id);
      });
    },

    //---- Event Sort Change

    onSortChange(params) {
      let field = "";
      if (params[0].field == "department_name") {
        field = "department_id";
      } else {
        field = params[0].field;
      }
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field
        }
      });
      this.Get_Designation(this.serverParams.page);
    },

    //---- Event Search
    onSearch(value) {
      this.search = value.searchTerm;
      this.Get_Designation(this.serverParams.page);
    },

    //---- Validation State Form
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    //------------- Submit Validation Create & Edit Designation
    Submit_Designation() {
      this.$refs.Create_Designation.validate().then(success => {
        if (!success) {
          showErrorNotification(this, 'Please fill the form correctly');
        } else {
          if (!this.editmode) {
            this.Create_Designation();
          } else {
            this.Update_Designation();
          }
        }
      });
    },

    //------------------------------ Modal (create Designation) -------------------------------\\
    New_Designation() {
      this.reset_Form();
      this.editmode = false;
      this.Get_Data_Create();
      this.$bvModal.show("New_Designation");
    },

    //------------------------------ Modal (Update Designation) -------------------------------\\
    Edit_Designation(designation) {
      this.Get_Designation(this.serverParams.page);
      this.reset_Form();
      this.Get_Data_Edit(designation.id);
      this.Get_departments_by_company();
      this.designation = designation;
      this.editmode = true;
      this.$bvModal.show("New_Designation");
    },

     
    Selected_Department(value) {
        if (value === null) {
            this.designation.department_id = "";
        }
    },


    Selected_Company(value) {
        if (value === null) {
            this.designation.department_id = "";
        }
        this.departments = [];
        this.designation.department_id = "";
        this.Get_departments_by_company();
    },

      //---------------------- Get_departments_by_company ------------------------------\\
        Get_departments_by_company() {
            axios
                .get(apiUrl+"/core/get_departments_by_company")
                .then(({ data }) => (this.departments = data));
        },

       //---------------------- Get_Data_Create  ------------------------------\\
          Get_Data_Create() {
            axios
                .get(apiUrl+"/designations/create")
                .then(response => {
                    this.companies   = response.data.companies;
                })
                .catch(error => {
                    
                });
        },

        //---------------------- Get_Data_Edit  ------------------------------\\
        Get_Data_Edit(id) {
          axios
              .get(apiUrl+"/designations/"+id+"/edit")
              .then(response => {
                  this.companies   = response.data.companies;
              })
              .catch(error => {
                  
              });
      },


    //--------------------------Get ALL designations ---------------------------\\

    Get_Designation(page) {
      // Start the progress bar.
      axios
        .get(apiUrl+
          "/designations?page=" +
            page +
            "&SortField=" +
            this.serverParams.sort.field +
            "&SortType=" +
            this.serverParams.sort.type +
            "&search=" +
            this.search +
            "&limit=" +
            this.limit
        )
        .then(response => {
          this.totalRows = response.data.totalRows;
          this.designations = response.data.designations;

          // Complete the animation of theprogress bar.
          this.isLoading = false;
        })
        .catch(response => {
          // Complete the animation of theprogress bar.
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        });
    },

    //------------------------------- Create designation ------------------------\\
    Create_Designation() {
      
      this.SubmitProcessing = true;
      axios
        .post(apiUrl+"/designations", {
          designation: this.designation.designation,
          department: this.designation.department_id,
        })
        .then(response => {
          this.SubmitProcessing = false;
          Fire.$emit("Event_Designation");
          showSuccessNotification(this, 'Created successfully!');
        })
        .catch(error => {
          this.SubmitProcessing = false;
          showErrorNotification(this, "Invalid Data")
        });
    },

    //------------------------------- Update designation ------------------------\\
    Update_Designation() {
      this.SubmitProcessing = true;
      axios
        .put(apiUrl+"/designations/" + this.designation.id, {
          designation: this.designation.designation,
          department: this.designation.department_id,
        })
        .then(response => {
          this.SubmitProcessing = false;
          Fire.$emit("Event_Designation");
          showSuccessNotification(this, 'Updated successfully!');
        })
        .catch(error => {
          this.SubmitProcessing = false;
          showErrorNotification(this, "Invalid Data")
        });
    },

    //------------------------------- reset Form ------------------------\\
    reset_Form() {
     this.designation = {
          id: "",
          designation: "",
          department_id: "",
      };
    },

    //------------------------------- Delete designation ------------------------\\
    Remove_Designation(id) {
      this.$swal({
        text: '',
        title: this.$t('Please confirm you want to delete this item?'),
        iconHtml: this.$helpers.swalIcon('alert.png'),
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .delete(apiUrl+"/designations/" + id)
            .then(() => {
              showSuccessNotification(this, 'Deleted successfully!');
              Fire.$emit("Delete_Designation");
            })
            .catch(() => {
              showErrorNotification(this, "Something went wrong while deleting item!");
            });
        }
      });
    },

    //---- Delete department by selection

    delete_by_selected() {
      this.$swal({
        text: '',
        title: this.$t('Please confirm you want to delete this item?'),
        iconHtml: this.$helpers.swalIcon('alert.png'),
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          // Start the progress bar.
          axios
            .post(apiUrl+"/designations/delete/by_selection", {
              selectedIds: this.selectedIds
            })
            .then(() => {
              showSuccessNotification(this, 'Deleted successfully!');
              Fire.$emit("Delete_Designation");
            })
            .catch(() => {
              // Complete the animation of theprogress bar.
              showErrorNotification(this, "Something went wrong while deleting item!");
            });
        }
      });
    }
  },

  //----------------------------- Created function-------------------\\

  created: function() {
    this.Get_Designation(1);
    this.Get_departments_by_company();

    Fire.$on("Event_Designation", () => {
      setTimeout(() => {
        this.Get_Designation(this.serverParams.page);
        this.$bvModal.hide("New_Designation");
      }, 500);
    });

    Fire.$on("Delete_Designation", () => {
      setTimeout(() => {
        this.Get_Designation(this.serverParams.page);
      }, 500);
    });
  }
};
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-good-table.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
